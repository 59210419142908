import React from "react"
import {graphql, Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import SettingsIcon from "../images/settings-icon.svg"
import UserIcon from "../images/user-plus-icon.svg"
import LoginIcon from "../images/login-icon.svg"
import Billboard from '../components/billboard'
import Questions from '../components/questions'
import Team from "../components/team"
import { Row, Col, Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = (props) => (
  <Layout pageInfo={{ pageName: "index" }}>
    <Billboard image={props.data.heroImage.childImageSharp.gatsbyImageData}/>
    <Container className="mb-5">
      <Row>
        <Col className="text-center" md={12}>
          <h2 className="section-title mb-5">Documents & Resources</h2>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mb-5" md={4}>
          <Link to="/hiring-managers" className="itemCategory">
            <UserIcon />
            <h3 className="title">For Hiring Managers</h3>
            <hr />
            <p>Go to Hiring Manager <br /> Resources</p>
          </Link>
        </Col>
        <Col className="text-center mb-5" md={4}>
          <Link to="/suppliers" className="itemCategory">
            <SettingsIcon />
            <h3 className="title">For Suppliers</h3>
            <hr />
            <p>Go to Supplier <br /> Resources</p>
          </Link>
        </Col>
        <Col className="text-center" md={4}>
          <a className="itemCategory" href="https://vms.vectorvms.com/Login_Entry.aspx">
            <LoginIcon/>
            <h3 className="title">Login to VectorVMS</h3>
            <hr />
            <p>Go to <br /> VectorVMS</p>
          </a>
        </Col>
      </Row>
    </Container>

    <Container className="mb-5">
      <Row>
        <Col md={7} className="d-flex align-content-center flex-wrap">
          <h2 className="section-title">About Our Partnership</h2>
          <p>As the managed service provider to the Commonwealth of Massachusetts, Sevenstep provides program management and oversees administrative temporary labor services for the Commonwealth, as well as serves as the state-wide provider used by the Executive Agencies and eligible entities for temporary help services (PRF71).</p>
          <p>Specifically, this MSP program provides the Commonwealth with contingent resources skilled in various temporary labor categories, including:</p>
          <ul className="col-md-6 mb-0">
            <li>Customer service</li>
            <li>Data entry</li>
            <li>Reception services</li>
          </ul>
          <ul className="col-md-6">
            <li>Paralegal services</li>
            <li>Accounting support</li>
            <li>Clerical support</li>
          </ul>
        </Col>
        <Col className="text-center" md={5}>
          <GatsbyImage image={props.data.AboutImage.childImageSharp.gatsbyImageData} alt=""/>
        </Col>
      </Row>
    </Container>
    <Questions />
    <Team />
  </Layout>
)

export default IndexPage;

export const Head = () => (
  <Seo title="Home" keywords={[`Sevenstep`, `Commonwealth`, `Resources`]} />
)

export const query = graphql`{
  heroImage: file(relativePath: {eq: "hero.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
  AboutImage: file(relativePath: {eq: "about.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 500
        quality: 100
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`;
